.contacts {
  padding-top: 90px;
  padding-right: 20px;
  padding-bottom: 40px;
  padding-left: 20px;
  margin-top: auto;
  text-align: center;
  word-break: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.name {
  margin-bottom: 18px;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: normal;
  color: var(--color-bravo);
  letter-spacing: normal;
}

.contact {
  display: block;
  margin-bottom: 2px;
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.43;
  color: var(--color-charlie);
  text-decoration: none;
  letter-spacing: normal;
}

.social-networks {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.social-network {
  display: flex;
  margin-right: 25px;

  &:last-child {
    margin-right: 0;
  }
}
