@import "styles/variables.scss";

.button {
  display: flex;
  align-items: center;
}

.button-auth {
  display: flex;
  align-items: center;
  width: 21px;
}

.button-signed {
  position: relative;
  top: 1.5px;
}

.share-button {
  padding-bottom: 1px;
}

.actions {
  display: flex;
  align-items: center;
  min-height: 38px;
  list-style: none;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.action {
  margin-right: 25px;
  margin-bottom: 2px;

  &:last-child {
    margin-right: 0;
  }
}
