.tour {
  font-size: 14px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  transform: translate(0, 0) !important;
  transition: 0.2s;

  button {
    border: 0 !important;
  }
}

.tour-content {
  display: block;
  width: 100%;
  height: 100%;
  padding: 50px 20px 70px;
}

.without-navi {
  padding-top: 30px;
  padding-bottom: 30px;
}

.tour-text {
  color: var(--color-black);
}

.close-tour {
  margin-top: 25px;
  width: 100%;
  max-width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}

.highlighted-mask {
  rx: 33px;
  ry: 33px;
}
