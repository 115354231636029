.navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  width: 100%;
  height: 100%;
  padding-bottom: 25px;
  overflow-y: auto;
  transition: 0.3s opacity;
}

.header {
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 25px;
  margin-bottom: 37px;
  background-color: var(--color-alpha);
  opacity: 0;
  transition: 0.3s opacity;

  &.active {
    border-bottom: 1px solid var(--color-india);
  }
}

.close {
  display: flex;
  align-items: center;
}

.navigation-items {
  position: relative;
  width: 100%;
  padding: 0 20px 20px;
}

.item {
  padding: 0 20px;
  margin-bottom: 34px;
  list-style-type: none;
  opacity: 0;
  transition: 0.35s opacity linear;
  color: var(--color-black);

  &:last-child {
    margin-bottom: 0;
  }
}

.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 34px;
  font-size: 16px;
  font-weight: 300;
  color: var(--color-black);
  text-decoration: none;
}

.subtitle {
  font-size: 20px;
  font-weight: 300;
  color: var(--color-black);
}

.go-back {
  display: inline-flex;
  align-items: center;
  margin: 0 auto 0 0;
}

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 34px;
  font-size: 16px;
  font-weight: 300;
  color: var(--color-bravo);
  text-decoration: none;
}

.mark-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.enter-animation {
  opacity: 0;
}

.enter-animation-done {
  opacity: 1;

  .header {
    opacity: 1;
  }

  @for $i from 1 through 1000 {
    .item:nth-child(#{$i}) {
      opacity: 1;
      transition-delay: (0.025 + calc($i/30)) + s;
    }
  }
}

.exit-animation {
  opacity: 1;
}

.exit-animation-active {
  opacity: 0;
  transition: opacity 100ms ease-in;
}

.exit-animation-done {
  opacity: 0;
}
