.navigation {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding-bottom: 25px;
  overflow-y: auto;
  transition: 0.3s opacity;
}

.header {
  position: sticky;
  top: 0;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 25px;
  margin-bottom: 37px;
  background-color: var(--color-alpha);
  opacity: 0;
  transition: 0.3s opacity;
}

.close {
  display: flex;
  align-items: center;
}

.navigation-items {
  position: relative;
  width: 100%;
  padding: 0 0 20px;
}

.item {
  padding: 0 25px;
  margin-bottom: 30px;
  list-style-type: none;
  opacity: 0;
  transition: 0.35s opacity linear;

  &:last-child {
    margin-bottom: 0;
  }
}

.image {
  display: block;
  width: 100%;
  margin-top: 15px;
  cursor: pointer;
}

.item-bottom {
  border-top: 1px solid var(--color-india);
  padding-top: 25px;
}

.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 34px;
  font-size: 16px;
  font-weight: 300;
  color: var(--color-bravo);
  text-decoration: none;
}

.button-auth {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 34px;
  font-size: 16px;
  font-weight: 300;
  color: var(--color-bravo);
  text-decoration: none;
  width: 21px;
}

.button-signed {
  position: relative;
  top: 1.5px;
}

.mark-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.enter-animation {
  opacity: 0;
}

.enter-animation-done {
  opacity: 1;

  .header {
    opacity: 1;
  }

  @for $i from 1 through 50 {
    .item:nth-child(#{$i}) {
      opacity: 1;
      transition-delay: (0.1 + calc($i/14)) + s;
    }
  }
}

.exit-animation {
  opacity: 1;
}

.exit-animation-active {
  opacity: 0;
  transition: opacity 100ms ease-in;
}

.exit-animation-done {
  opacity: 0;
}
