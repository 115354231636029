.root {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  width: 100%;
  height: 100%;
}

.clickable-content {
  z-index: 100;
}

.transparent {
  position: fixed;
  // position: absolute;
  z-index: 9999999999;

  display: block;

  cursor: pointer;
}
