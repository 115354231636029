.overlay {
  position: relative;
  z-index: 9;
  cursor: default !important;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    display: block;
    width: calc(100% + var(--offset));
    height: calc(100% + var(--offset));
    margin-top: calc(var(--offset) / 2 * -1);
    margin-left: calc(var(--offset) / 2 * -1);
    box-shadow: 0 0 0 2000000px rgb(0 0 0 / 30%);
  }
}

.circle::after {
  display: block;
  border-radius: 50%;
}

.lock-scroll {
  position: fixed;
  left: 0;
  width: 100%;
  height: fill-available;
  overflow: hidden;
  touch-action: unset;
  padding-right: var(--scrollbar-width);
}
