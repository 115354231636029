@import "@app/styles/variables.scss";

.wrapper {
  position: relative;
}

.slick-bottom {
  bottom: 0 !important;
  top: auto !important;
}

.root {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.43;
  padding: 23px 30px 25px;
  color: var(--common-color-black);
  background-color: var(--common-color-white);
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.06);
  z-index: 222;
  position: absolute;
  top: 0;
  width: 286px;
  text-align: center;
  margin: var(--space);

  @media screen and (max-width: $breakpoint-md) {
    width: 237px;
  }

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -4px;
    width: 8px;
    height: 4px;
  }
}

.width-image {
  width: 320px;
  padding: 0;
}

.desktop-left {
  @media screen and (min-width: $breakpoint-md) {
    left: 0;
  }
}

.desktop-left-end {
  @media screen and (min-width: $breakpoint-md) {
    left: 100%;
  }
}

.desktop-center,
.desktop-bottom-center {
  @media screen and (min-width: $breakpoint-md) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.desktop-right {
  @media screen and (min-width: $breakpoint-md) {
    right: 0;
  }
}

.desktop-right-none {
  @media screen and (min-width: $breakpoint-md) {
    right: 0;
  }
}

.mobile-right-end {
  @media screen and (max-width: $breakpoint-md) {
    right: 100%;
  }
}

.mobile-left {
  @media screen and (max-width: $breakpoint-md) {
    left: 0;
  }
}

.mobile-left-end {
  @media screen and (min-width: $breakpoint-md) {
    left: 100%;
  }
}

.mobile-center,
.mobile-bottom-center {
  @media screen and (max-width: $breakpoint-md) {
    left: 50%;
    transform: translateX(-50%);
  }
}

.mobile-right {
  @media screen and (max-width: $breakpoint-md) {
    right: 0;
  }
}

.mobile-none {
  @media screen and (max-width: $breakpoint-lg) {
    position: fixed;
    top: auto;
    width: 100vw;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.mobile-none {
  @media screen and (max-width: $breakpoint-md) {
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.desktop-right-end {
  @media screen and (max-width: $breakpoint-md) {
    right: 100%;
  }
}

.desktop-left::before,
.desktop-left-end::before {
  @media screen and (min-width: $breakpoint-md) {
    left: 0;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='4px' height='4px' viewBox='0 0 4 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='print-shop-(final)' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='vigbo-776-book-mvp-(v2)-33' transform='translate(-798.000000, -440.000000)' fill='%23FFFFFF'%3E%3Cg id='background' transform='translate(798.000000, 440.000000)'%3E%3Cpolygon id='Path' transform='translate(2.000000, 2.000000) scale(-1, 1) translate(-2.000000, -2.000000) ' points='0 4 4 0 4 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      left bottom no-repeat;
  }
}

.mobile-left::before,
.mobile-left-end::before {
  @media screen and (max-width: $breakpoint-md) {
    left: 0;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='4px' height='4px' viewBox='0 0 4 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='print-shop-(final)' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='vigbo-776-book-mvp-(v2)-33' transform='translate(-798.000000, -440.000000)' fill='%23FFFFFF'%3E%3Cg id='background' transform='translate(798.000000, 440.000000)'%3E%3Cpolygon id='Path' transform='translate(2.000000, 2.000000) scale(-1, 1) translate(-2.000000, -2.000000) ' points='0 4 4 0 4 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      left bottom no-repeat;
  }
}

.desktop-center::before,
.desktop-bottom-center::before {
  @media screen and (min-width: $breakpoint-md) {
    left: 50%;
    transform: translateX(-50%);
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='8px' height='4px' viewBox='0 0 8 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='print-shop-(final)' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='vigbo-776-book-mvp-(v2)-29' transform='translate(-733.000000, -584.000000)' fill='%23FFFFFF'%3E%3Cg id='background' transform='translate(624.000000, 584.000000)'%3E%3Cpolygon id='Path' points='109 4 113 0 117 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      center top no-repeat;
  }
}
.mobile-center::before,
.mobile-bottom-center::before {
  @media screen and (max-width: $breakpoint-md) {
    left: 50%;
    transform: translateX(-50%);
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='8px' height='4px' viewBox='0 0 8 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='print-shop-(final)' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='vigbo-776-book-mvp-(v2)-29' transform='translate(-733.000000, -584.000000)' fill='%23FFFFFF'%3E%3Cg id='background' transform='translate(624.000000, 584.000000)'%3E%3Cpolygon id='Path' points='109 4 113 0 117 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      center top no-repeat;
  }
}

.desktop-right::before {
  @media screen and (min-width: $breakpoint-md) {
    right: 0;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='4px' height='4px' viewBox='0 0 4 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='print-shop-(final)' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='vigbo-776-book-mvp-(v2)-33' transform='translate(-798.000000, -440.000000)' fill='%23FFFFFF'%3E%3Cg id='background' transform='translate(798.000000, 440.000000)'%3E%3Cpolygon id='Path' points='0 4 4 0 4 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      right bottom no-repeat;
  }
}
.mobile-right::before {
  @media screen and (max-width: $breakpoint-md) {
    right: 0;
    background: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='4px' height='4px' viewBox='0 0 4 4' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='print-shop-(final)' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='vigbo-776-book-mvp-(v2)-33' transform='translate(-798.000000, -440.000000)' fill='%23FFFFFF'%3E%3Cg id='background' transform='translate(798.000000, 440.000000)'%3E%3Cpolygon id='Path' points='0 4 4 0 4 4'%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E")
      right bottom no-repeat;
  }
}

.desktop-bottom-center::before,
.mobile-bottom-center::before {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%) scaleY(-1);
}

.desktop-right-none::before {
  @media screen and (min-width: $breakpoint-md) {
    display: none;
  }
}
.mobile-none::before {
  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}
