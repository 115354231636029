@import "styles/variables.scss";

.header {
  position: sticky;
  top: 0;
  z-index: 3;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: var(--color-alpha);
  border-bottom: 1px solid var(--color-india);

  @media screen and (max-width: $breakpoint-md) {
    padding-top: 22px;
    padding-bottom: 0;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-lg) {
    align-items: flex-start;
  }
}

.logo {
  max-width: 200px;
}
