@import "styles/variables.scss";

.without-scenes {
  padding-bottom: 13px;

  @media screen and (max-width: $breakpoint-md) {
    padding-bottom: 22px;
  }
}

.actions {
  display: flex;
  align-items: center;
}

.action {
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }
}

.button {
  display: flex;
  align-items: center;
}

.actions {
  display: flex;
  list-style: none;

  @media screen and (max-width: $breakpoint-lg) {
    display: none;
  }
}

.wrapper {
  @media screen and (max-width: $breakpoint-lg) {
    width: 100%;
    max-width: 100%;
    overflow-x: auto;
  }

  &::-webkit-scrollbar {
    width: 0px;
    height: 0;
    overflow-x: scroll;
    background: transparent;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
  }
}

.scenes {
  display: flex;
  flex-grow: 1;
  align-items: center;
  margin-right: 50px;
  margin-left: 50px;

  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 25px;
    margin-right: 0;
    margin-left: 0;
  }

  @media screen and (max-width: $breakpoint-md) {
    position: relative;
    margin-top: 23px;
    margin-left: 0;
    overflow-y: initial;
    overscroll-behavior: contain;
  }
}

.scene {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 20px 30px 20px 0;

  text-transform: uppercase;

  &:last-child {
    padding-right: 0;
  }

  @media screen and (max-width: $breakpoint-md) {
    padding: 8px 0;
    padding-right: 25px;
  }
}

.anchor {
  font-size: 13px;
  font-weight: 500;
  line-height: 1;
  color: var(--color-bravo);
  text-decoration: none;
  letter-spacing: 1px;
  cursor: pointer;
  transition: 0.2s color;

  &:hover {
    color: var(--color-charlie);
  }
}

.show-more {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  padding: 12px 0;
  font-size: 13px;
  font-weight: 500;
  color: var(--color-bravo);
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}

.hidden {
  // overflow: hidden;
  // pointer-events: none;
  // visibility: hidden;
  // opacity: 0;
}

.position-absolute {
  position: absolute;
}
