.button {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  font-size: 16px;
  letter-spacing: 1px;
}

.lists {
  bottom: 0;
  left: 50%;
  padding: 15px 20px;
  background-color: var(--color-juliet);
  transform: translate(-50%, 100%);
}

.scene {
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}

.anchor {
  width: 100%;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  line-height: 1;
  color: var(--color-bravo);
  text-decoration: none;
  text-transform: none;
  cursor: pointer;
}
