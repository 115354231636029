@import "styles/variables.scss";

.root {
  position: relative;
  animation-fill-mode: forwards;
  animation-name: menuIn;
  animation-duration: 0.3s;
  z-index: 51;
  .inner {
    min-width: 280px;
    position: absolute;
    background-color: var(--spec-color-white);
    padding: 25px 30px;
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.06);
  }

  &[data-position="down"] {
    .inner {
      top: calc(100% + 15px);
    }
  }

  &[data-position="down-right"] {
    .inner {
      top: calc(100% + 15px);
      right: 0;
    }
  }

  &[data-position="down-left"] {
    .inner {
      top: calc(100% + 15px);
      left: 0;
    }
  }

  &[data-position="top"] {
    .inner {
      bottom: calc(100% + 15px);
    }
  }

  &[data-position="top-right"] {
    .inner {
      bottom: calc(100% + 15px);
      right: 0;
    }
  }

  &[data-position="top-left"] {
    .inner {
      bottom: calc(100% + 15px);
      left: 0;
    }
  }
}

.text {
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  font-stretch: normal;
  line-height: 1.43;
  color: var(--color-bravo);
  margin-bottom: 15px;
  @media screen and (max-width: $breakpoint-lg) {
    font-size: 14px;
  }
}

.approve,
.cancel {
  display: inline-block;
  font-size: 11px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
  color: var(--color-bravo);
  margin-right: 20px;
  transition: 0.2s all;
  &:hover {
    color: var(--color-charlie);
  }
}

.cancel {
  color: var(--color-charlie);
  &:hover {
    opacity: 0.8;
  }
}

.blackout {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;

  width: 100%;
  height: 100%;

  background-color: rgba(0, 0, 0, 0.3);

  visibility: hidden;

  opacity: 0;

  pointer-events: none;
  touch-action: none;

  &.without-fon {
    background: transparent;
  }
}

.open {
  visibility: visible;

  opacity: 1;

  overflow-y: auto;

  pointer-events: auto;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 100%;
  max-width: 450px;
  padding: 50px 30px;

  background-color: var(--color-alpha);

  transform: translate(-50%, -50%);

  overflow: auto;

  @media screen and (max-width: $breakpoint-lg) {
    height: 100%;
    max-width: 100%;
    min-height: 100%;
    padding: 25px;
  }
}

.mobile-type {
  @media screen and (max-width: $breakpoint-lg) {
    top: auto;
    left: 0;
    bottom: 0;
    height: 100vh;
    max-height: 100vh;
    transform: none;
    padding-top: 40px;
    padding-bottom: 40px;
    .modal {
      height: auto;
      max-width: 100%;
      min-height: 150px;
      padding: 45px 25px 25px;
      top: auto;
      bottom: 0;
      transform: translate(-50%, 0);
    }
  }
}

.back {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;
  max-height: 100vh;

  @media screen and (max-width: $breakpoint-lg) {
    display: flex;
  }
}

.line-close {
  display: none;
  position: absolute;
  width: 40px;
  height: 16px;
  left: 50%;
  top: 3px;
  margin-left: -20px;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 3px;
    width: 100%;
    background-color: var(--color-super-silver);
  }
  @media screen and (max-width: $breakpoint-lg) {
    display: block;
  }
}

@keyframes menuIn {
  from {
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes menuOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
    visibility: hidden;
  }
}
