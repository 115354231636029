@import "styles/variables.scss";

.gradient-right {
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: block;
    width: 40px;
    height: 40px;
    pointer-events: none;
    content: "";
    background: linear-gradient(to left, var(--color-white), rgba(255, 255, 255, 0));

    body[class$="theme-dark"] & {
      background: linear-gradient(to left, rgba(20, 20, 20, 1), rgba(20, 20, 20, 0));
    }
  }
}

.gradient-left {
  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: block;
    width: 40px;
    height: 40px;
    content: "";
    background: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));

    body[class$="theme-dark"] & {
      background: linear-gradient(to right, rgba(20, 20, 20, 1), rgba(20, 20, 20, 0));
    }
  }
}

.navigation {
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (max-width: $breakpoint-lg) {
    flex-wrap: wrap;
    overflow-x: hidden;

    :global(.lock-scroll) & {
      overflow-x: visible;
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    position: relative;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .no-scenes {
    padding-bottom: 22px;
  }
}
