@import "styles/variables.scss";

.password-screen {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100vh;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: auto;
    min-height: 100%;
    content: "";
    background-color: rgba(0, 0, 0, 0.6);
  }

  @media only screen and (max-height: $breakpoint-md) {
    min-height: $iphone-5-height;
  }
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 390px;
  margin: 0 auto;
}

.cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.logo {
  position: relative;
  z-index: 1;
  margin-top: 20px;
  font-size: 20px;
  font-weight: 300;
  color: var(--color-alpha);
  text-decoration: none;

  @media screen and (max-width: $breakpoint-md) {
    display: none;
  }
}

.form {
  position: relative;
  z-index: 1;
  max-width: 100%;
  margin-top: auto;
  margin-bottom: auto;

  @media screen and (max-width: $breakpoint-md) {
    display: flex;
    flex-grow: 1;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    padding: 15px 40px 0 40px;
  }
}

.footer {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;

  @media screen and (orientation: landscape) and (max-width: $breakpoint-sm) {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.copyright {
  font-size: 10px;
  font-weight: 700;
  font-weight: 500;
  color: var(--color-alpha);
  text-align: center;
  letter-spacing: 0.5px;

  a {
    color: var(--color-alpha);
    text-decoration: none;

    body[class="theme-dark"] & {
      color: var(--common-color-white);
    }
  }

  body[class="theme-dark"] & {
    color: var(--common-color-smoke-screen);
  }
}

.title {
  max-width: 100%;
  overflow: hidden;
  font-family: var(--heading-font-family);
  font-size: 36px;
  font-weight: var(--heading-font-weight);
  line-height: 42px;
  color: var(--common-color-white);
  text-align: center;
  letter-spacing: 0.49px;
  overflow-wrap: break-word;

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
    line-height: normal;
  }
}

.prompt {
  margin-top: 10px;
  margin-bottom: 35px;
  font-size: 14px;
  font-weight: 300;
  color: var(--color-delta);
  text-align: center;

  body[class="theme-dark"] & {
    color: var(--common-color-smoke-screen);
  }

  @media screen and (max-width: $breakpoint-md) {
    margin-top: 12px;
    margin-bottom: 34px;
    line-height: 1.43;
  }
}

.input {
  display: block;
  width: 100%;
  padding: 16px 20px;
  font-size: 14px;
  font-weight: 300;
  color: var(--common-color-white);
  background-color: transparent;
  border: 1px solid #e5e5e5;
  border-radius: 0;
  transition: 0.2s border-color;
  -webkit-appearance: none;

  &:invalid {
    box-shadow: none;
  }

  &::placeholder {
    color: var(--color-delta);
  }

  &:focus {
    border: 1px solid #cccccc;
    outline: none;
  }
}

.button {
  display: block;
  width: 100%;
  margin-top: 30px;
  font-weight: bold;
  background-color: var(--common-color-white);
  color: #000000;

  &:hover {
    box-shadow: 0 0 0 2px var(--common-color-white);
  }
  svg {
    stroke: #141414;
  }
}

.label {
  position: relative;
  display: block;
  width: 390px;

  @media screen and (max-width: $breakpoint-md) {
    width: 100%;
  }
}

.show-password {
  position: absolute;
  top: 50%;
  right: 20px;
  display: flex;
  transform: translateY(-50%);
}

.error {
  border-color: var(--color-kilo);
}

.error-message {
  margin-top: 5px;
  font-size: 14px;
  font-weight: 300;
  color: var(--color-kilo);

  @media screen and (max-width: $breakpoint-md) {
    line-height: 1.43;
  }
}

.scalable-field {
  width: 114.2% !important;
  min-height: 62.8px !important;
  font-size: 16px !important;
  transform: scale(0.875) !important;
  transform-origin: left !important;
}
