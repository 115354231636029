@import "@app/styles/variables";

.root {
  padding: 8px;
  background-color: var(--color-white-fon);
  cursor: pointer;
}

.image-block {
  position: relative;
  min-width: 304px;
  min-height: 172px;
  background-color: #eeeeee;
  margin-bottom: 12px;
}

.image {
  position: relative;
  width: 100%;
  max-height: 180px;
  object-fit: contain;
  display: block;
}

.description {
  color: var(--color-black);
  margin-bottom: 6px;
  white-space: pre-line;
}

.fon {
  position: absolute;
  width: 62px;
  height: 62px;
  top: 50%;
  left: 50%;
  margin-top: -36px;
  margin-left: -28px;
  object-fit: cover;
  object-position: center center;
}

.labels {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  grid-gap: 10px;
  z-index: 1;

  @media only screen and (max-width: $breakpoint-md) {
    top: 8px;
    right: 8px;
  }
}

.label {
  position: relative;
  padding: 10px 12px 9px;
  border-radius: 6px;
  font-size: 10px;
  font-weight: bold;
  line-height: 2;
  letter-spacing: 0.55px;
  color: var(--color-black);
  background-color: var(--color-white);
}

.discount-label {
  position: relative;
  padding: 10px 12px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: bold;
  color: var(--color-black);
  background-color: var(--color-white);
}
