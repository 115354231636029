@import "styles/variables.scss";

.cart {
  display: flex;
  margin-right: 25px;
}

.download,
.burger-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.download {
  margin-right: 25px;
}

.mobile-actions {
  display: flex;
  align-items: center;
  align-self: center;
  margin: 0 0 0 auto;

  @media screen and (min-width: $breakpoint-lg) {
    display: none;
  }
}

.mobile-menu-background {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  pointer-events: none;
  touch-action: none;
  visibility: hidden;
  background-color: var(--color-alpha);
  opacity: 0;
  transition: 350ms all;

  &.visible {
    z-index: 2;
    visibility: visible;
    opacity: 1;
  }
}
