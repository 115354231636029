.button {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 34px;
  font-size: 16px;
  font-weight: 300;
  color: var(--color-bravo);
  text-decoration: none;
}

.mark-icon {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
