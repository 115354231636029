.root {
  position: absolute;
  z-index: 1;

  display: none;

  width: 160px;
  max-height: calc(100vh - 93px);

  background-color: var(--color-alpha);

  overflow-y: auto;
}

.visible {
  display: block;
}
